import React from "react"
import Helmet from 'react-helmet';
import { graphql } from "gatsby"
import Layout from "../components/layout";


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { site, markdownRemark } = data // data.markdownRemark holds your post data
  const { siteMetadata } = site
  const { frontmatter, html } = markdownRemark

  
  return (
    <>
    <Layout>
      <Helmet>
        <title>{frontmatter.title} | {siteMetadata.title}</title>
        <meta name="robots" content="noindex"></meta>
        <meta name="description" content={frontmatter.metaDescription} />
        <meta name="author" content="Schatzkammer Lübeck" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
     
 
  <section class="bg-hero-texture ">
  <div class="container mx-auto p-4">
      <div class="w-full align-top md:w-6/12 px-4 mr-auto ml-auto">
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-2 shadow-lg rounded-lg bg-gray-500">
          <img alt="..." src={frontmatter.thumbnail}>
          </img>
        </div>
      </div>
    </div>
</section>
     
     
     </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        metaDescription
      }
    }
  }
`